.table {
  display: block;
  overflow: auto;

  tbody {
    display: block;
    width: 100%;
  }

  .row {
    display: flex;

    td:nth-child(2) {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
  }

  height: 100%;

  .thumbnail {
    width: 48px;
    height: 48px;
    object-fit: cover;
  }

  tr:not(:global(.disabled)) {
    cursor: pointer;
  }

  tr:global(.disabled) > td > img {
    filter: grayscale(1);
  }
}
