.table {
  td {
    vertical-align: middle;
  }
}

.policieModal {
  @media (min-width: 576px) and (max-width: 1200px) {
    max-width: 90%;
  }
}
