.tableContainer {
  height: 605px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.loading {
    overflow: hidden;
  }
}

.loadingSpinner {
  position: absolute;

  top: 50%;
  left: 50%;
}
