.status-switch-box {
  display: flex;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0;
  line-height: 1.5;
}

.pulsate-btn {
  animation: pulsate 3s infinite;
}

.search-product-form-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.search-product-form-group > .search-product-form-field {
  flex: 1 1 auto;
}

.products-spreadsheet-progress-bar {
  height: 16px;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}

.products-spreadsheet-progress-bar.completed {
  height: 0;
}

#search-barcode-form-group > button > span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.catalogues-form {
  scroll-margin-bottom: 100px;
}

.disabled-product-row img {
  filter: grayscale(1);
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
