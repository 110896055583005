.hiddenFile {
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  background-color: #007bff;
  border-color: #007bff;

  svg {
    margin-right: 8px;
  }
}