.block {
  position: relative;
  background: #fff;
  border-radius: 10px;
  padding: 1.2rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
  transition: transform 0.25s ease, box-shadow 0.25s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
  }
}
