.container {
  display: flex;
  width: 100%;
  gap: 15px;

  .searchButton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
  }
}
