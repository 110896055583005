.progressButton {
  width: 205px;
  color: white !important;
  text-align: center;
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: var(--progress, 0%);
    height: 100%;
    background-color: #007bff;
    z-index: 0;
    transition: width 0.2s ease;
  }
}

.progressContent {
  position: relative;
  z-index: 1;
  margin: 0 auto;
}

.spinner {
  color: black;
}
