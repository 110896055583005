.hidden-file {
  width: 0px;
  height: 0px;
  overflow: hidden;
  display: none;
}

.box {
  min-height: 200px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px dashed #dddddd;
  padding: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  img {
    width: 480px;
    max-width: 100%;
    text-align: center;
    box-sizing: border-box;
    vertical-align: middle;
    transition: 0.1s;
  }
}
