td {
  vertical-align: middle !important;
}

.floatingButtons {
  position: fixed;
  z-index: 999;
  bottom: 1rem;
  right: 5rem;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    text-align: center;
    border-radius: 0;
    padding: 18px;
    text-transform: uppercase;
    transition: all 0.2s ease-out;
    border-radius: 10px;
    background-color: #DC3545;

    svg {
      color:white;
      font-size: 30px;
    }

    @media (max-width: 480px) {
      width: 56px;
      height: 56px;
      padding: 0;
    }

    @media (max-width: 420px) {
      width: 48px;
      height: 48px;
      padding: 0;
    }
  }
}