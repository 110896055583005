@import '../variables.scss';

.top-nav {
  background-image: linear-gradient(90deg, #353434, #2e2d2d, #2d2c2c);
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  padding: 0 10px 0 0;
  position: fixed;
  left: 0;
  right: 0;
  height: $sidebar-height;
  z-index: 100;

  .button-menu-mobile {
    outline: none;
    border: none;
    color: #fff;
    display: inline-block;
    height: $sidebar-height;
    line-height: $sidebar-height;
    width: 60px;
    background-color: transparent;
    font-size: 24px;
    cursor: pointer;
  }

  .button-menu-mobile.disable-btn {
    display: none;
  }

  .menu {
    list-style: none;

    > li {
      float: left;
    }

    .nav-link {
      padding: 0 15px;
      color: rgba(#fff, 0.6);
      min-width: 32px;
      display: block;
      line-height: $sidebar-height;
      text-align: center;
      max-height: $sidebar-height;
    }
  }

  .dropdown.show {
    .navLink {
      background-color: rgba(#fff, 0.05);
    }
  }

  .button-menu-mobile {
    border: none;
    color: #fff;
    display: inline-block;
    height: $sidebar-height;
    line-height: $sidebar-height;
    width: 60px;
    background-color: transparent;
    font-size: 24px;
    cursor: pointer;
  }

  .button-menu-mobile.disable-btn {
    display: none;
  }
}

.logo {
  text-align: center;
  display: block;
  line-height: $sidebar-height;

  span.logo-large {
    display: block;
  }
  
  span.logo-small {
    display: none;
  }

  .logo-large-text-dark {
    color: #323a46;
    font-weight: 700;
    font-size: 22px;
    text-transform: uppercase;
  }

  .logo-large-text-light {
    color: #fff;
    font-weight: 700;
    font-size: 22px;
    text-transform: uppercase;
  }
}

.logo-large img {
  height: 46px;
}

.logo-small img {
  height: 32px;
  margin-left: 4px;
}

.logo-large img,
.logo-small img {
  background-color: #fff;
  padding: 6px;
  border-radius: 10px;
}

.logo-box {
  height: $sidebar-height;
  width: $sidebar-width;
  float: left;

  @media (max-width: 767.98px) {
    display: none;
  }
}
