form {
  input#value {
    max-width: 64px;
  }

  input#cash_value {
    max-width: 125px;
  }

  input#expires_at_date::-webkit-calendar-picker-indicator {
    padding: 0;
    margin: 0;
  }
}
