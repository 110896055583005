.status-switch-box {
  display: flex;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0;
  line-height: 1.5;
}

.container-format {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .format {
    padding: 5px 15px;
    border-radius: 2.8rem;
    font-size: 12px;
    font-weight: 600;
    color: #40444f;
    border-style: solid;
    border-width: 2px;
    border-color: #bec9ce;
  }
}

.vertical-divider {
  height: 2rem;
  width: 2px;
  background-color: #cfd0d3;
}
