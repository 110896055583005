.status-switch-box {
  display: flex;
  max-width: fit-content;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0;
  line-height: 1.5;
  align-items: center;

  span {
    margin-left: 5px;
  }
}
