.box img {
  width: auto;
  max-width: 100%;
  height: 120px;
}

fieldset {
  #description,
  #opening_hours {
    min-height: 120px;
  }
}

.disabled-tab {
  pointer-events: none;
  opacity: 0.6;
}