@import 'variables.scss';

#wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.content-page,
.content-account {
  overflow: hidden;
  min-height: 80vh;
  margin-top: $sidebar-height;

  @media (min-width: 769px) {
    padding: 0 15px 5px 15px;
    margin-left: $sidebar-width;
  }
}

.collapsed {
  .logo {
    span.logo-large {
      display: none;
    }

    span.logo-small {
      display: block;
    }
  }

  .logo-box {
    width: 80px;
  }

  @media (min-width: 769px) {
    .content-page {
      margin-left: $sidebar-collapsed-width;
    }
  }
}
