$sidebar-height: 70px;

$sidebar-bg-color: white;
$sidebar-color: #979797 !default;

$sidebar-width: 240px !default;
$sidebar-collapsed-width: 80px !default;

$highlight-color: #2d2c2c !default;

$submenu-bg-color: #2b2b2b !default;
$submenu-bg-color-collapsed: #2b2b2b !default;

$icon-bg-color: white !default;
$icon-size: 30px !default;

$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
